<template>

  <div class="board-writer">
    <span class="sub"><i class="fa fa-pencil" aria-hidden="true"></i> 글쓰기</span>
    <input type="text" class="bw_title" v-model="title" placeholder="제목을 입력하세요">
    <textarea class="bw_content" v-model="content" placeholder="내용을 입력하세요"></textarea>

    <div class="btn-area">
      <button class="btn02 bw1" @click="saveBoard()" style="font-weight: bold;font-size: 14px">{{btnText}}</button>
    </div>
  </div>

</template>

<script>
  import {getBoardList, saveBoard} from "@/network/userRequest";
  import sportsConst from "@/common/sportsConst";

  export default {
    name: "FreeBoardWriter",
    props: {
      from: {
        type: Number,
        default: 0 //0게시판에서 쓰는글 ,1 베팅내역에서 쓴느글 (2일경우 베팅내역체크 여부검사)
      },
      betIds: {
        type: Array,
        default: []
      },
      btype: {
        type: Number,
        default: sportsConst.GAME_PROTO
      },
      btnText:{
        type: String,
        default: '등록'
      }
    },
    data() {
      return {
        title: '',
        content: '',
        editorOption: {
          modules: {
            toolbar: [
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
              [{font: []}],
            ], //기능바 세팅
          },
          placeholder: '내용을 입력하세요', //提示
          readyOnly: false, //是否只读
          theme: 'bubble', //skin snow/bubble
          syntax: false, //문법체크
        },
      }
    },
    methods: {
      saveBoard() {
        if (this.title.trim() === '') {
          this.$swal({
            title: '제목을 입력하세요',
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
          return false;
        }
        // let regx = /^(?=.*[가-힣ㄱ-ㅎ].*).{1,}$/
        // if (!(regx.test(this.content))) {
        //   //if(false){
        //   this.$swal({
        //     title: '한글이 포함된 내용으로 작성해주세요',
        //     type: 'error',
        //     showCancelButton: false,
        //     showConfirmButton: true
        //   })
        //   return false;
        // }
        //베팅내역에서 게시글 작성시 베팅내역체크여부를 검사
        if (this.from) {
          if (this.betIds.length === 0) {
            //if(false){
            this.$swal({
              title: '등록하실 베팅내역을 체크하세요',
              type: 'error',
              showCancelButton: false,
              showConfirmButton: true
            })
            return false;
          }
        }
        saveBoard({
          'title': this.title,
          'content': this.content,
          'bids': this.betIds,
          'btype': this.btype
        }).then(res => {
          if (res.data.success) {this.$swal({
            title: '게시글 작성이 완료되였습니다',
            type: 'success',
            showCancelButton: false,
            showConfirmButton: true
          })

            this.content = ''
            this.title = ''
            this.$emit('saveBoardSuccess');
          } else {
            this.$swal({
              title: res.data.msg,
              type: 'error',
              showCancelButton: false,
              showConfirmButton: true
            })
          }
        })
      },
      onEditorBlur(editor) {
      },
      onEditorFocus(editor) {
      },
      onEditorReady(editor) {
      },
      onEditorChange(editor) {
        this.content = editor.html;
      },
    }
  }
</script>

<style scoped>
</style>